import { Formik } from "formik"
import React from "react"
import styled from "styled-components"
import * as Yup from "yup"

import { Button, Password, Input } from "../../../components"
import { useMerchantRegister, useNextUrl } from "../../hooks"
import { validateEmail } from "../../validationObjects"

const FormWrapper = styled.form`
  button {
    margin-top: 20px;
  }
`

const Header = styled.p`
  margin-top: 40px;
  margin-bottom: 15px;
  font-size: 15px;
`

const InfoText = styled.p<{
  bottomMargin: string
}>`
  font-size: 11px;
  line-height: 15px;
  margin-bottom: ${({ bottomMargin }) => bottomMargin};
`

const validationSchema = Yup.object({
  email: validateEmail,
  password: Yup.string().required("Password required"),
})

const MerchantForm: React.FC = () => {
  const registerMerchant = useMerchantRegister()
  const nextUrl = useNextUrl()

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        try {
          const success = await registerMerchant(values)
          if (success) {
            nextUrl("/dashboard/")
          }
        } catch (err) {
          console.warn(err)
        }
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <FormWrapper onSubmit={handleSubmit}>
          <Header>Sign up using your merchant login details.</Header>
          <InfoText
            data-cy-test="merchant-sign-up-info-text"
            bottomMargin="15px"
          >
            Please use your Yoco merchant portal login details. Please note this
            will create a separate Reseller account.
          </InfoText>
          <InfoText
            data-cy-test="merchant-sign-up-info-text"
            bottomMargin="25px"
          >
            By signing up as a certified reseller, your merchant referral link
            will be deactivated - please review terms in the reseller agreement.
          </InfoText>
          <Input
            cypressTestId="merchant-sign-up-email"
            placeholder="Email *"
            name="email"
            type="email"
          />
          <Password
            cypressTestId="merchant-sign-up-password"
            placeholder="Password *"
            name="password"
          />
          <Button
            cypressTestId="merchant-sign-up-submit"
            label="Login"
            disabled={isSubmitting}
            loading={isSubmitting}
            type="submit"
            color="#018567"
            textColor="white"
          />
        </FormWrapper>
      )}
    </Formik>
  )
}

export default MerchantForm
