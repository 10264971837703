import React, { useState } from "react"
import styled from "styled-components"

import AuthFormWrapper from "../auth/components/AuthFormWrapper"
import MerchantForm from "../auth/sign-up/components/MerchantForm"
import NewCustomerForm from "../auth/sign-up/components/NewCustomerForm"
import { RadioToggle } from "../components"
import Seo from "../components/seo"

const ToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 20px;

  h3 {
    color: ${({ theme }) => theme.palette.mineShaft};
    font-size: 18px;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 0px;
    text-align: left;
    font-weight: 500;
  }
`

const toggleFormOptions = [
  {
    id: "isMerchant",
    label: "Yes",
    value: "merchant",
    defaultChecked: false,
  },
  {
    id: "notMerchant",
    label: "No",
    value: "notMerchant",
    defaultChecked: true,
  },
]

const SignUpPage: React.FC = () => {
  const [isMerchant, setIsMerchant] = useState(false)

  const OnResellerChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e) {
      setIsMerchant(e.target?.value === "merchant")
    }
  }

  return (
    <AuthFormWrapper title="Let's make you a certified Yoco Reseller">
      <Seo title="Yoco - Sign up" />

      <ToggleContainer>
        <h3 data-cy-test="sign-up-type-toggle-title">
          Are you currently a Yoco merchant?
        </h3>
        <RadioToggle
          cypressTestId="sign-up-type-toggle"
          name="merchantOrNewToggle"
          options={toggleFormOptions}
          size="small"
          OnRadioToggle={OnResellerChanged}
        />
      </ToggleContainer>
      {isMerchant ? <MerchantForm /> : <NewCustomerForm />}
    </AuthFormWrapper>
  )
}

export default SignUpPage
