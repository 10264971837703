import { Formik } from "formik"
import React, { useLayoutEffect, useState } from "react"
import styled from "styled-components"
import * as Yup from "yup"

import { cleanQueryParam } from "../../../auth/utils"
import {
  Button,
  CheckboxInput,
  Input,
  LabeledLink,
  Password,
} from "../../../components"
import { getUrlSearchParams } from "../../../utils/navigation"
import IdentificationInputGroup from "../../components/IdentificationInputGroup"
import { useNextUrl, useSignUp } from "../../hooks"
import {
  validateEmail,
  validateFirstName,
  validateIdentity,
  validateLastName,
  validateNewPassword,
  validatePhone,
} from "../../validationObjects"

const FormContainer = styled.div`
  .fieldHeader {
    margin-top: 25px;
    margin-bottom: 15px;
    font-size: 15px;
  }
  .flex {
    display: block;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    .half {
      width: auto;
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
      display: flex;
      .half {
        width: 50%;
      }
    }
  }

  .link {
    color: ${({ theme }) => theme.palette.outrageousOrange};
    text-decoration: underline;
  }
`

const validationSchema = Yup.object({
  firstName: validateFirstName,
  lastName: validateLastName,
  email: validateEmail,
  phoneNumber: validatePhone,
  password: validateNewPassword,
  recruitmentCode: Yup.string(),
  processing: Yup.bool()
    .oneOf([true], "You must accept the terms and conditions")
    .required("Accept terms and conditions"),
}).concat(validateIdentity)

const defaultValues = {
  firstName: "",
  lastName: "",
  email: "",
  idNumber: "",
  passportNumber: "",
  phoneNumber: "",
  password: "",
  recruitmentCode: "",
  processing: false,
}

const NewCustomerForm: React.FC = () => {
  const signUp = useSignUp()
  const nextUrl = useNextUrl()
  const [initialValues, setInitialValues] = useState(defaultValues)

  useLayoutEffect(() => {
    const queryParams = getUrlSearchParams()
    const recruitmentCode = cleanQueryParam(queryParams.recruitment_code)
    setInitialValues({ ...defaultValues, recruitmentCode: recruitmentCode })
  }, [])

  return (
    <FormContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          try {
            const success = await signUp(values)
            if (success) {
              nextUrl("/dashboard/")
            }
          } catch (err) {
            console.warn(err)
          }
        }}
        enableReinitialize
      >
        {({ handleSubmit, isSubmitting, values }) => (
          <form onSubmit={handleSubmit}>
            <div className="flex">
              <div className="half">
                <Input
                  cypressTestId="sign-up-first-name"
                  placeholder="First Name *"
                  name="firstName"
                />
              </div>
              <div className="half">
                <Input
                  cypressTestId="sign-up-last-name"
                  placeholder="Last Name *"
                  name="lastName"
                />
              </div>
            </div>
            <Input
              cypressTestId="sign-up-email"
              placeholder="Email *"
              name="email"
              type="email"
            />
            <IdentificationInputGroup
              IdCypressTestId="sign-up-id-field"
              PassportCypressTestId="sign-up-passport-field"
              idAdded={!!values.idNumber}
              passportAdded={!!values.passportNumber}
            />
            <Input
              cypressTestId="sign-up-phone-number"
              placeholder="Phone Number *"
              name="phoneNumber"
              additionalLabel="+27"
            />
            <Password
              cypressTestId="sign-up-password"
              placeholder="Password *"
              name="password"
            />
            <p className="fieldHeader">
              Has a current reseller given you their unique code to sign up?
            </p>
            <Input
              cypressTestId="sign-up-recruitment-code"
              placeholder="Enter unique reseller code (optional)"
              name="recruitmentCode"
            />
            <CheckboxInput
              cypressTestId="sign-up-consent"
              id="new-customer"
              name="processing"
            >
              <span>
                * I consent to Yoco processing my personal information in
                accordance with the terms of the Referral Agreement and the Yoco{" "}
                <a
                  data-cy-test="sign-up-privacy-policy-link"
                  className="link"
                  href={process.env.GATSBY_PRIVACY_POLICY_LINK}
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
                .
              </span>
            </CheckboxInput>
            <Button
              cypressTestId="sign-up-submit"
              label="Submit"
              disabled={!values.processing || isSubmitting}
              loading={isSubmitting}
              type="submit"
              color="#018567"
              textColor="white"
            />
            <LabeledLink
              label="already a Yoco reseller?"
              linkText="Login"
              linkPath="/sign-in/"
            />
          </form>
        )}
      </Formik>
    </FormContainer>
  )
}

export default NewCustomerForm
